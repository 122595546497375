import React, { useState } from 'react';
import {
  Typography,
  TextField,
  FormHelperText,
  MenuItem,
  Box,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import axios from 'axios';
import InputWrap from '../atoms/InputWrap';
import StyledButton from '../atoms/StyledButton';
import MaskedTextField from '../molecules/MaskedTextField';
import AutocompleteCityUF from './AutocompleteCityUF';
import { monthlyInvoicing } from '../functions/Options';
import {
  EmailValidation,
  LengthValidation,
  NameValidation,
  ObjectValidation,
  PhoneValidation,
  LastNameValidation,
  CnpjValidation,
} from '../functions/FormValidation';
import Hubspot from '../functions/Hubspot';
import { getPlanPrice, getPlanName } from '../functions/HowMuch';
import { InfashionFormOptions } from '../../static/files/Campaigns/TaNaModa';
import AllowedCities from '../../static/files/AllowedCities.json';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '25px',

    '& .labels': {
      color: `${theme.palette.midGrey.main} !important`,
      marginTop: '30px',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginBottom: '20px',
    },
  },
  buttonWrap: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
  inputSelf: {
    '& .MuiFormControl-root, & .MuiAutocomplete-root': {
      width: '100%',
      marginTop: '10px',
    },
  },
}));

export const CampaignTransformationForm = ({
  name, setResultData, isLoading, setIsLoading, typeForm,
}) => {
  const classes = useStyles();
  const [error, setError] = useState({});
  const [data, setData] = useState({});
  const { businessSegment, activities } = InfashionFormOptions[typeForm];

  const page = typeForm === 'switchAccount' ? 'trocar-contador' : 'transformacao';

  const validateFields = () => (
    ObjectValidation(data, 12)
    && LengthValidation(data)
    && NameValidation(data)
    && LastNameValidation(data)
    && PhoneValidation(data)
    && EmailValidation(data)
    && CnpjValidation(data)
  );

  const isFormValid = () => {
    try {
      return validateFields();
    } catch (e) {
      return false;
    }
  };

  const validateForm = () => {
    try {
      if (validateFields()) {
        setError({});
      }
    } catch (e) {
      setError({ field: e.fileName, message: e.message });
      return false;
    }
    return true;
  };

  const changeData = (value, field) => {
    setData({ ...data, [field]: value });
    setError({});
  };

  const currencyFormat = (value, field) => {
    const currencyValue = `R$ ${value.replace(/\D/g, '')
      .replace(/\B(?=(\d{2}$)+(?!\d))/g, ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    changeData(currencyValue, field);
  };

  const integerFormat = (value) => {
    if (value) {
      return value.toString().replace('R$ ', '').replace(/\./g, '').replace(/,/g, '');
    }

    return 0;
  };

  const categorizeProfits = () => {
    const profit = integerFormat(data.profit);
    let profitCategory;
    if (profit <= 3000000) {
      // R$ 30.000,00
      profitCategory = 1;
    } else if (profit <= 6000000) {
      //  R$ 60.000,00
      profitCategory = 2;
    } else if (profit <= 10000000) {
      //  R$ 100.000,00
      profitCategory = 3;
    } else if (profit <= 15000000) {
      //  R$ 150.000,00
      profitCategory = 4;
    } else if (profit <= 20000000) {
      //  R$ 200.000,00
      profitCategory = 5;
    } else {
      profitCategory = 6;
    }

    return profitCategory;
  };

  const calcAccountingPrice = () => {
    const contributorsPrice = 2990;
    const bankAccountPrice = 1000;

    const bankAccountsFree = 1;

    let finalPrice = getPlanPrice(data.segment, data.monthly);

    const { contributors, bankAccounts } = data;

    finalPrice += contributors * contributorsPrice;

    if (bankAccounts > bankAccountsFree) {
      finalPrice += (bankAccounts - bankAccountsFree) * bankAccountPrice;
    }

    const profitCategory = categorizeProfits();
    if (profitCategory < 5) {
      finalPrice += (profitCategory - 1) * 5000;
    }

    return (finalPrice / 100).toFixed(2).replace('.', ',');
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const result = {
        form: page,
        plan: {
          name: getPlanName(data.segment, data.monthly),
          price: calcAccountingPrice(),
        },
        currentAccountant: data.price.replace('R$ ', ''),
      };

      const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID;
      const formGuid = process.env.GATSBY_HUBSPOT_TROCAR_CONTADOR_FORM_GUID;

      const cidade = data.cityName;

      // Retorna a cidade ou undefined caso não é válida
      const isAllowedCity = AllowedCities.cities.find((city) => city.name == cidade) === undefined;

      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

      const dataHubspot = {
        fields: [
          {
            name: 'firstname',
            value: data.name,
          },
          {
            name: 'lastName',
            value: data.lastName,
          },
          {
            name: 'phone',
            value: data.phone,
          },
          {
            name: 'email',
            value: data.email,
          },
          {
            name: 'atividade',
            value: data.activity,
          },
          {
            name: 'segmento_atuacao',
            value: data.segment,
          },
          {
            name: 'faturamento_mensal_estimado',
            value: data.monthly,
          },
          {
            name: 'valor_contabilidade_atual',
            value: data.price,
          },
          {
            name: 'qtde_funcionarios',
            value: data.contributors,
          },
          {
            name: 'cnpj',
            value: data.cnpj,
          },
          {
            name: 'cidade_estado',
            value: `${data.cityName} - ${data.state}`,
          },
          {
            name: 'campanha_interna',
            value: typeForm === 'transformation' ? 'CC0013' : 'CC0012',
          },
        ],
        context: {
          pageUri: window.location.href,
          pageName: document.title,
        },
      };

      try {
        const cloudfare = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
        if (cloudfare.data) {
          const ipInfo = cloudfare.data.split('\n')[2];
          if (ipInfo.indexOf('ip=') > -1) {
            dataHubspot.context.ipAddress = ipInfo.replace('ip=', '');
          }
        }

        await axios.post(url, dataHubspot);
        setResultData(isAllowedCity);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError({ message: 'Desculpe, ocorreu um erro. Tente novamente mais tarde!' });
      }

      setTimeout(() => {
        Hubspot.sendHowMuchSwitchAccountant(result, data.email);
        setIsLoading(false);
        setResultData(result);
      }, 800);
    }
  };

  const handleCityUFChange = (value) => {
    if (value) {
      setData({
        ...data,
        state: value.stateInitial,
        city: value.code,
        cityName: value.name,
      });
    } else {
      setData({
        ...data,
        state: null,
        city: null,
        cityName: null,
      });
    }
  };

  return (
    <Box className={classes.root}>
      <form
        onSubmit={(e) => onSubmit(e)}
        className="FORM-QUANTO-CUSTA-TROCAR-CONTADOR"
        name={name}
      >
        <Typography variant="button" className="labels">Dados pessoais</Typography>
        <InputWrap>
          <TextField
            label="Nome"
            name="firstname"
            value={data.name}
            onChange={(e) => changeData(e.target.value, 'name')}
            error={error.field === 'name'}
            disabled={isLoading}
          />

          <TextField
            label="Sobrenome"
            name="lastName"
            value={data.lastName}
            onChange={(e) => changeData(e.target.value, 'lastName')}
            error={error.field === 'lastName'}
            disabled={isLoading}
          />

          <MaskedTextField
            onChange={(e) => changeData(e.target.value, 'phone')}
            mask="+55 (99) 99999-9999"
            textFieldProps={{
              label: 'Telefone',
              name: 'phone',
              type: 'tel',
              value: data.phone,
              floating: true,
              error: error.field === 'phone',
            }}
            InputProps={{
              disabled: isLoading,
            }}
          />

          <TextField
            label="E-mail"
            name="email"
            type="email"
            value={data.email}
            onChange={(e) => changeData(e.target.value, 'email')}
            error={error.field === 'email'}
            disabled={isLoading}
          />
        </InputWrap>

        <Typography variant="button" className="labels">Sobre sua empresa</Typography>
        <InputWrap hasMargin={false}>
          <TextField
            label="Qual será a atividade ?"
            name="atividade"
            value={data.activity}
            onChange={(e) => changeData(e.target.value, 'activity')}
            disabled={isLoading}
            select
          >
            {activities.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}

          </TextField>

          <TextField
            label="Qual segmento de atuação?"
            name="segmento_atuacao"
            value={data.segment}
            onChange={(e) => changeData(e.target.value, 'segment')}
            disabled={isLoading}
            select
          >
            {businessSegment.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

        </InputWrap>

        <Box className={classes.inputSelf}>
          <TextField
            label="Faturamento estimado"
            name="faturamento_mensal_estimado"
            value={data.monthly}
            onChange={(e) => changeData(e.target.value, 'monthly')}
            disabled={isLoading}
            select
          >
            {monthlyInvoicing.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <InputWrap>
          <TextField
            label="Mensalidade contabilidade atual"
            name="valor_contabilidade_atual"
            value={data.price}
            onChange={(e) => currencyFormat(e.target.value, 'price')}
            error={error.field === 'price'}
            disabled={isLoading}
          />

          <AutocompleteCityUF
            onChange={(value) => handleCityUFChange(value)}
            value={{
              stateInitial: data.state,
              code: data.city,
              name: data.cityName,
            }}
            disabled={isLoading}
          />

          <TextField
            label="Número de funcionários"
            name="qtde_funcionarios"
            type="number"
            value={data.contributors}
            onChange={(e) => changeData(e.target.value, 'contributors')}
            error={error.field === 'contributors'}
            disabled={isLoading}
          />

          {/* <TextField
            label="Número de contas bancárias"
            name="qtde_contas_bancarias"
            type="number"
            value={data.bankAccounts}
            onChange={(e) => changeData(e.target.value, 'bankAccounts')}
            error={error.field === 'bankAccounts'}
            disabled={isLoading}
          /> */}
          <MaskedTextField
            onChange={(e) => changeData(e.target.value, 'cnpj')}
            mask="99.999.999/9999-99"
            textFieldProps={{
              label: 'CNPJ',
              name: 'cnpj',
              type: 'text',
              value: data.cnpj,
              floating: true,
              error: error.field === 'cnpj',
            }}
            InputProps={{
              disabled: isLoading,
            }}
          />

        </InputWrap>

        <FormHelperText>{error.message}</FormHelperText>

        <Box onClick={validateForm} className={classes.buttonWrap}>
          <StyledButton
            text="CADASTRAR NA PROMOÇÃO"
            type="submit"
            disabled={!isFormValid() || isLoading}
            variant="tnm"
            color="tnm"
            width="100%"
          />
        </Box>
      </form>
    </Box>
  );
};

export default CampaignTransformationForm;
