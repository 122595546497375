import React from 'react';
import ContentWrap from '../../atoms/ContentWrap';
import Spacer from '../../atoms/Spacer';
import ContentSpacer from '../../molecules/ContentSpacer';
import TransformationHero from '../../molecules/TransformationHero';
import ConubeSolutions from '../../organisms/ConubeSolutions';
import CustomerComments from '../../organisms/CustomerComments';
import DigitalBanksIntegration from '../../organisms/DigitalBanksIntegration';
import DigitalCertificate from '../../organisms/DigitalCertificate';
import Functionalities from '../../organisms/Functionalities';
import SwitchAccountantSchema from '../../../static/files/SwitchAccountantSchema.json';
import FAQList from '../../molecules/FAQList';
import OpenCompanyStepByStep from '../../molecules/OpenCompanyStepByStep';
import FashionStepByStep from '../../molecules/FashionStepByStep';

const Transformation = () => (
  <>
    <TransformationHero />
    <Spacer size={50} />

    <ContentWrap>
      {/* <AdvantageForm form="openCompany" /> */}
      <FashionStepByStep home taNaModa color="#8645FF" stepColor="purple" type="openCompany" />
      <Functionalities taNaModa color="tnm" hasCustomerComment />
      <DigitalBanksIntegration taNaModa color="tnm" />
      <DigitalCertificate taNaModa color="tnm" />
    </ContentWrap>

    <ContentSpacer />
    <ConubeSolutions taNaModa />
    <ContentSpacer />

    <ContentWrap>
      <CustomerComments />
      <FAQList questions={SwitchAccountantSchema.mainEntity} color="tnm" />
    </ContentWrap>

    <Spacer size={100} />
  </>
);

export default Transformation;
