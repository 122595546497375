import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import SwipeableViews from 'react-swipeable-views';
import HowMuchSwitchAccountant from './HowMuchSwitchAccountant';
import FeedbackMessage from '../molecules/FeedbackMessage';
import CheckIcon from '../../static/images/check-icon-outline.svg';
import AdvantageForm from '../molecules/AdvantageForm';
import CampaignTransformationForm from './CampaignTransformationForm';
import CenteredLoading from '../atoms/CenteredLoading';

const TransformationFormDrawer = ({ noHeight, advantages, typeForm }) => {
  const useStyles = makeStyles((theme) => ({
    overflowHide: {
      marginTop: '5%',
      '& .react-swipeable-view-container div': {
        overflow: 'hidden',
      },
    },
    form: {
      padding: '15px 30px 40px 30px',

      '@media screen and (max-width: 959px)': {
        padding: '15px 10px 40px 10px',
      },
    },
  }));

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (resultData !== null) {
      setTabValue(1);
    }
  }, [resultData]);

  return (
    <SwipeableViews
      index={tabValue}
      className={classes.overflowHide}
    >
      <Box className={classes.form}>
        <CampaignTransformationForm
          name="Trocar de Contador | Calculadora"
          setResultData={setResultData}
          setIsLoading={setIsLoading}
          typeForm={typeForm}
        />
        <AdvantageForm form={advantages} />
        {isLoading && <CenteredLoading />}
      </Box>
      <div>
        <FeedbackMessage noHeight={noHeight} error={resultData} />
      </div>
    </SwipeableViews>
  );
};

export default TransformationFormDrawer;
