import React from 'react';
import MainFashion from '../../components/pages/MainFashion';
import Transformation from '../../components/pages/business/Transformation';

const TaNaModa = () => (
  <MainFashion page="transformacao">
    <Transformation />
  </MainFashion>
)

export default TaNaModa;