import {
  Grid, Typography, makeStyles, Box,
} from '@material-ui/core';
import React, { useState } from 'react';
import HeroBackground from './HeroBackground';
import StyledButton from '../atoms/StyledButton';
import FormCard from '../atoms/FormCard';

import TransformationFormDrawer from '../organisms/TransformationFormDrawer';
import StyledDrawer from '../atoms/StyledDrawer';
import HeaderInFashion from '../organisms/HeaderInFashion';
import { useDrawerContext, useStateDrawerContext } from '../../contexts/DrawerContext';
import HeroBackgroundTaNaModa from './HeroBackgroundTaNaModa';
import AdvantageForm from './AdvantageForm';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  picture: {
    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  },
  button: {
    display: 'none',
    maxWidth: '85%',

    '@media screen and (max-width: 959px)': {
      display: 'block',
      maxWidth: '100%',
    },
  },
  boxAdvantage: {
    display: 'none',
    '@media screen and (max-width: 959px)': {
      display: 'block',
    },
  },
  hero: {
    minHeight: '50vh',
  },

}));

const TransformationHero = () => {
  const classes = useStyles();
  const { setOpenDrawer } = useDrawerContext();
  const { openDrawer } = useStateDrawerContext();

  return (

    <>
      <HeaderInFashion openDrawer={() => setOpenDrawer(!openDrawer)} />
      <HeroBackgroundTaNaModa page="transformacao">
        <Grid container spacing={2} className={classes.root}>
          <Grid item md={6} xs={12} className="text">
            <Typography className="span">
              <strong>Tá na moda</strong>
              {' '}
              empreender economizando
            </Typography>
            <Typography variant="h3" component="h1" className="title">
              Sua MEI para ME
              {' '}
              <strong>com desconto na contabilidade</strong>
              {' '}
              e
              {' '}
              <strong>ganhando os Certificados Digitais</strong>
            </Typography>

            {/* <Typography variant="body1" component="h2" className={classes.description}>
            Sua empresa cresce, você ganha o E-CPF e E-CNPJ (Mais de R$ 400 no total),
            e ainda passa a contar com uma contabilidade digital, simples e prática
            </Typography> */}
            <Box className={classes.boxAdvantage}>
              <AdvantageForm form="transformation" colorIcon="#FFFFFF" />
            </Box>
            <Box className={classes.button}>
              <StyledButton
                text="QUERO APROVEITAR"
                variant="tnm"
                color="tnm"
                width="100%"
                onClick={() => setOpenDrawer(true)}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className={classes.picture}>
            <FormCard color="info" taNaModa className={classes.hero}>
              <TransformationFormDrawer advantages="transformation" typeForm="transformation" />
            </FormCard>
          </Grid>
        </Grid>
        {/* Mobile */}
        <StyledDrawer
          open={openDrawer}
          setOpen={setOpenDrawer}
          taNaModa
        >
          <TransformationFormDrawer noHeight typeForm="transformation" />
        </StyledDrawer>
      </HeroBackgroundTaNaModa>
    </>
  );
};

export default TransformationHero;
